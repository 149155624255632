import React from 'react';
import './Home.css'; // Ensure your CSS is correctly linked

const Home: React.FC = () => {
    return (
        <div>
            <header>
                <div className="container">
                    <h1>Earnings</h1>
                    <p className="subtitle">Professional KPI Updates for Financial Analysis</p>
                </div>
            </header>
            <main>
                <div className="container">
                    <h2>Enhance Your Financial Modeling with Timely KPI Updates</h2>
                    <p>In the dynamic landscape of finance, <span className="emphasis">access to current data is paramount</span>. Earnings provides rapid KPI updates through our robust API and Excel add-in, enabling you to make informed decisions based on the latest financial information.</p>
                    <h3>Optimize Your Workflow with Earnings:</h3>
                    <ul className="feature-list">
                        <li><strong>Efficient API Integration:</strong> Access KPI updates programmatically within minutes</li>
                        <li><strong>Seamless Excel Add-in:</strong> Refresh your financial models with current data effortlessly</li>
                        <li><strong>High-volume Processing:</strong> Handle hundreds of KPIs with speed and precision</li>
                        <li><strong>Enterprise-grade Implementation:</strong> Integrate seamlessly with your existing systems</li>
                        <li><strong>Automated Data Management:</strong> Minimize manual entry and reduce potential for errors</li>
                    </ul>
                    <p>In rapidly evolving markets, delayed data can significantly impact analysis quality. With Earnings, ensure your financial models are continuously updated with the most current KPIs available, providing you with a competitive edge.</p>
                    <a href="#" className="cta-button">Optimize Your KPI Updates</a>
                </div>
            </main>
        </div>
    );
};

export default Home;